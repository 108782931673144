<template>
  <div class="up" flex column center align>
    <div v-if="!img">
      <i class="iconfont icon-shangchuantupian"></i>
      <p>点击上传图片</p>
      <p>格式：JPG、JPEG、PNG</p>
      <input type="file" class="key_input" @change="change($event)" id="file" />
    </div>
    <img :src="img" width="100%" height="100%" v-if="img" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: "",
    };
  },
  props: {
    type: {
      type: [Number, String],
    },
    is_over: {
      type: [Number, String, Boolean],
      default: "" || "false" || false,
    },
  },
  methods: {
    change(e) {
      let file_input = e.target.files[0];
      let suffix = file_input.name.toUpperCase()
        .substr(file_input.name.lastIndexOf("."));
      if (suffix == ".JPG" || suffix == ".JPEG" || suffix == ".PNG") {
        let that = this;
        var reader = new FileReader();
        reader.readAsDataURL(file_input);
        reader.onload = function () {
          that.img = reader.result;
          that.$emit("change", reader.result, that.type);
        };
      } else {
        this.$alert("您的上传的图片格式不正确!", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.up {
  width: 21.9%;
  height: 100%;
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  position: relative;
  margin-right: 20px;
  .iconfont {
    color: rgba(224, 224, 224, 1);
    font-size: 45px;
    opacity: 0.8;
  }
  p:nth-of-type(1) {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  p:nth-of-type(2) {
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
  }
  .key_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    opacity: 0;
  }
  > img {
    width: 100%;
    height: 100%;
  }
}
</style>