<template>
  <div class="page" flex center align>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      flex
      align
      flex-end
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Object, Number],
      default: 1,
    },
    callback: {
      type: Function,
    },
  },
  methods: {
    handleSizeChange(val) {
      this.page.limit = val;
      this.callback(this.page.page, val);
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.callback(val, this.page.limit);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 50px;
  padding: 8px 0;
  /deep/.el-input__inner:nth-of-type(1) {
    height: 32px !important;
    width: 100px !important;
  }
}
</style>